import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

const styles = theme => ({
  search: {
    backgroundColor: '#fff',
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  input: {
    padding: '5px 7px',
    width: '100%',
    fontSize: 24,
    fontFamily: [
      'Rubik'
    ].join(','),
    border: 'none',
    '&:focus': {
      borderColor: '#19B5FE',
      boxShadow: '0 0 0 0.2rem rgba(25,181,254,.25)',
      outline: '#19B5FE'
    },
  },
  button: {
    height: 'inherit',
    width: 50,
    backgroundColor: '#355D9A',
    border: 'none',
    borderRadius: 0,
    '&:focus': {
      borderColor: '#19B5FE',
      boxShadow: '0 0 0 0.2rem rgba(25,181,254,.25)',
      outline: '#19B5FE'
    },
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    height: 26,
    width: 'auto',
    fill: '#fff'
  }
});

class App extends Component {
  state = {
    keyword: '',
  };

  handleChange = event => {
    this.setState({ keyword: event.target.value });
  };

  handleSubmitEnter = ({ key }) => {
    if(key === "Enter") {
      this.handleSubmit();
    }
  }

  handleSubmit = () => {
    let params = [];
    
    params.push('q=' + encodeURIComponent(this.state.keyword));
    window.location = `${process.env.REACT_APP_SEARCHKIT_BASE_URL}/?` + params.join('&');
  };

  render = () => {
    const { classes } = this.props;

    return (
      <div className={ classes.search }>
        <input id='keyword' value={ this.state.value } onChange={ this.handleChange } className={ classes.input } placeholder="Zoeken" onKeyPress={ this.handleSubmitEnter }/>
        <button className={ classes.button } onClick={ this.handleSubmit }>
          <SearchIcon className={ classes.icon } />
        </button>
      </div>
    );
  }
}

export default withStyles(styles)(App);